<template>
  <div class="event-share-overlay">
    <div class="overlay-controls">
      <span class="close-btn material-icons" @click="$emit('close')">
        close
      </span>
    </div>
    <div class="overlay-body">
      <div class="overlay-title">
        {{ $t("you_committed") }}
      </div>

      <div class="overlay-subtitle">
        {{ $t("share_modal_subtitle") }}
      </div>

      <!-- <div v-if="imagePreview">
        <img :src="imagePreview" alt="" />
      </div> -->

      <!-- type selector -->
      <div class="slider-loader" v-if="isLoading.slider">
        <v-progress-circular
          indeterminate
          color="#FFFFFF"
          size="40"
          width="3"
        ></v-progress-circular>
      </div>
      <template v-if="!isLoading.slider">
        <splide
          ref="splide"
          :options="splideOptions"
          @splide:active="onSlideChange"
        >
          <!-- stories -->
          <splide-slide>
            <div class="stories-slide-wrap">
              <div class="stories-slide-wrap-body">
                <div class="stories-slide" id="stories-file">
                  <div class="stories-slide-overlay">
                    <div class="overlay-text">
                      <span>{{ eventName }}</span>
                      <span> {{ eventDate }} </span>
                    </div>
                    <div class="spartan-logo">
                      <img
                        src="@/assets/spartan_logo_with_title.svg"
                        alt="spartan"
                      />
                    </div>
                  </div>
                  <img
                    src="@/assets/event_share_stories.png"
                    class="slide-image"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </splide-slide>
          <!-- feed -->
          <!-- <splide-slide>
            <div class="feed-slide-wrap">
              <div class="feed-slide-wrap-body">
                <div class="feed-slide" id="feed-file">
                  <div class="feed-slide-overlay">
                    <div class="spartan-logo">
                      <img
                        src="@/assets/spartan_logo_with_title.svg"
                        alt="spartan"
                      />
                    </div>
                    <div></div>
                    <div class="overlay-text">
                      <span>{{ eventName }}</span>
                      <span>{{ eventDate }}</span>
                    </div>
                  </div>
                  <img
                    src="@/assets/event_share_feed.png"
                    class="slide-image"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </splide-slide> -->
        </splide>
      </template>

      <div class="slider-pagination">
        <div
          class="pagination-item"
          :class="{ 'is-active': selectedSlideIndex === 0 }"
        ></div>
        <div
          class="pagination-item"
          :class="{ 'is-active': selectedSlideIndex === 1 }"
        ></div>
      </div>

      <div class="overlay-buttons">
        <div
          class="file-download"
          :class="{
            'download-stories-file': selectedSlideIndex === 0,
            'download-feed-file': selectedSlideIndex === 1,
          }"
          @click="shareFile('download')"
        >
          <i class="material-icons" v-if="!isLoading.download"> download </i>
          <v-progress-circular
            v-if="isLoading.download"
            indeterminate
            color="#000000"
            size="25"
            width="2"
          ></v-progress-circular>
        </div>
        <mem-button
          :class="{
            'share-stories-btn': selectedSlideIndex === 0,
            'share-feed-btn': selectedSlideIndex === 1,
          }"
          :btn-type="'secondary-dark'"
          :loading="isLoading.share"
          @click="shareFile('share')"
        >
          <span v-if="selectedSlideIndex === 0">{{
            $t("share_modal_btn_stories")
          }}</span>
          <span v-if="selectedSlideIndex === 1">{{
            $t("share_modal_btn_feed")
          }}</span>
        </mem-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import html2canvas from "html2canvas";
// TODO: Remove Splide usage
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    Splide,
    SplideSlide,
  },
  data: () => ({
    isLoading: {
      download: false,
      share: false,
      slider: true,
    },
    // imageFile: null,
    imagePreview: null,
    selectedSlide: null,
    // slide indexes:
    // 0 - story slide
    // 1 - feed slide
    selectedSlideIndex: 0,
    splideOptions: {
      arrows: false,
      pagination: false,
      perPage: 3,
      perMove: 1,
      focus: "center",
      trimSpace: false,
      autoWidth: true,
      gap: 50,
      height: 320,
      // width: 375,
    },
    logsList: "(0_0)",
  }),
  props: {
    eventName: {
      Type: String,
      default: "Untitled event",
    },
    startDate: {
      Type: String,
      default: "1815-12-10",
    },
  },
  computed: {
    ...mapGetters(["userData"]),
    eventDate() {
      return moment(this.startDate).format("MMM DD, YYYY");
    },
  },
  methods: {
    ...mapActions(["handleToolbarNotification"]),
    async getImage() {
      let el;
      if (this.selectedSlideIndex === 0)
        el = document.getElementById("stories-file");
      if (this.selectedSlideIndex === 1)
        el = document.getElementById("feed-file");
      // let el = this.selectedSlide;

      let options = {
        // scale: 1,
        imageTimeout: 0,
        logging: false,
        backgroundColor: "#000000",
      };
      let canvas = await html2canvas(el, options);

      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpeg", 0.95)
      );

      return {
        file: new File([blob], `${this.eventName}.jpeg`, {
          type: blob.type,
        }),
        src: URL.createObjectURL(blob),
      };
      // this.imageFile = new File([blob], `${this.eventName}.jpeg`, {
      //   type: blob.type,
      // });
      // this.imagePreview = URL.createObjectURL(blob);
    },
    async shareFile(shareType) {
      if (this.isLoading[shareType]) return;

      this.isLoading[shareType] = true;
      try {
        let image = await this.getImage();
        this.isLoading[shareType] = false;

        let res = await navigator.share({ files: [image.file] });
        console.log(res, "Share result");
        // GTM Event
        if (this.selectedSlideIndex === 0)
          this.pushDataLayerEvent("commitmentShareStories");
        if (this.selectedSlideIndex === 1)
          this.pushDataLayerEvent("commitmentShareFeed");

        this.$emit("close");
      } catch (error) {
        this.pushLog(error, "error");
        // this.$emit("close");
        // this.handleToolbarNotification({
        //   type: "warning",
        //   text: error,
        //   isVisible: true,
        //   closeTimeout: 3000,
        // });
      }
    },
    onSlideChange(component, { slide }) {
      let { splide } = this.$refs;
      this.selectedSlide = slide;
      this.selectedSlideIndex = splide.index;
    },
    async downloadFile() {
      if (this.isLoading.download) return;

      this.isLoading.download = true;

      let image = await this.getImage();
      let a = document.createElement("a");
      a.href = image.src;
      // a.target = "_blank"
      // if (!this.isMobile) a.setAttribute("download", fileName);
      a.setAttribute("download", `${this.eventName}.jpeg`);
      this.isLoading.download = false;
      // a.download = fileName;
      a.click();
    },
    pushLog(text, type) {
      let color = "#121212";
      if (type === "error") color = "#cf1019";

      this.logsList =
        this.logsList + `<br><span style="color: ${color}">${text}</span>`;

      console.log(this.logsList);
    },
    pushDataLayerEvent(event) {
      if (!window.dataLayer) return;

      return window.dataLayer.push({
        event,
        accountId: this.userData.id,
        raceName: this.eventName,
      });
    },
  },
  watch: {},
  created() {
    this.pushDataLayerEvent("commitmentShareVisible");
  },
  destroyed() {},
  mounted() {
    // Splide lib correct display hack;
    setTimeout(() => {
      this.isLoading.slider = false;
    }, 300);
  },
};
</script>
<style lang="scss" scoped>
.event-share-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  background-color: #121212;
  height: 100%;
  .overlay-controls {
    // position: sticky;
    // top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .close-btn {
      color: #717171;
      padding-left: 20px;
      padding-right: 20px;
      @include cursorPointer;
    }
  }
  .overlay-body {
    max-width: 375px;
    width: 100%;
    .overlay-title {
      font-family: "Druk Wide";
      font-size: 18px;
      font-weight: 950;
      line-height: 16px;
      text-transform: uppercase;

      text-align: center;
      margin-top: 22px;
      margin-bottom: 22px;
    }
    .overlay-subtitle {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      letter-spacing: 0.0025em;
      padding: 0 50px;
      margin-bottom: 38px;
      text-align: center;
    }
    .slider-loader {
      height: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .slider-pagination {
      // display: flex;
      display: none;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding-top: 18px;
      padding-bottom: 4px;
      .pagination-item {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        margin: 0 4px;
        background-color: #383838;
      }
      .is-active {
        background-color: #ffffff;
      }
    }
    .overlay-buttons {
      display: grid;
      grid-template-columns: max-content 1fr;
      padding: 30px 40px;
      .file-download {
        height: 44px;
        width: 44px;
        color: #000000;
        background-color: #ffffff;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        @include cursorPointer;
      }
    }
  }
  .slide-image {
    border-radius: 5px;
  }
  .spartan-logo {
    img {
      height: 26px;
      width: 130px;
    }
  }
  .overlay-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    font-family: "MonoSpec";
  }

  .stories-slide-wrap {
    position: relative;
    width: 180px;
    .stories-slide-wrap-body {
      position: absolute;
      .stories-slide {
        transform: scale(0.48);
        transform-origin: 0% 0%;
        // position: absolute;
        .stories-slide-overlay {
          position: absolute;
          height: 100%;
          width: 100%;

          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-top: 42px;
          padding-left: 28px;
          padding-right: 28px;
          padding-bottom: 32px;

          // text-align: center;
          .spartan-logo {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
          .overlay-text {
            font-size: 20px;
            line-height: 22px;

            // min-height: 110px;
            padding-bottom: 16px;
          }
        }
      }
    }
  }

  .feed-slide-wrap {
    position: relative;
    width: 285px;
    .feed-slide-wrap-body {
      position: absolute;
      .feed-slide {
        width: 770px;
        transform: scale(0.37);
        transform-origin: 0% 35%;
        .feed-slide-overlay {
          position: absolute;
          height: 100%;
          width: 100%;

          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: flex-end;
          justify-content: flex-end;

          padding-top: 36px;
          padding-right: 46px;
          padding-bottom: 28px;
          padding-left: 36px;

          .spartan-logo {
            position: absolute;
            top: 50%;
            left: -2%;
            transform: rotate(270deg);
          }
          .overlay-text {
            font-size: 18px;
            line-height: 20px;

            height: 100px;
          }
        }
      }
    }
  }
}
</style>