var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-share-overlay"},[_c('div',{staticClass:"overlay-controls"},[_c('span',{staticClass:"close-btn material-icons",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" close ")])]),_c('div',{staticClass:"overlay-body"},[_c('div',{staticClass:"overlay-title"},[_vm._v(" "+_vm._s(_vm.$t("you_committed"))+" ")]),_c('div',{staticClass:"overlay-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("share_modal_subtitle"))+" ")]),(_vm.isLoading.slider)?_c('div',{staticClass:"slider-loader"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#FFFFFF","size":"40","width":"3"}})],1):_vm._e(),(!_vm.isLoading.slider)?[_c('splide',{ref:"splide",attrs:{"options":_vm.splideOptions},on:{"splide:active":_vm.onSlideChange}},[_c('splide-slide',[_c('div',{staticClass:"stories-slide-wrap"},[_c('div',{staticClass:"stories-slide-wrap-body"},[_c('div',{staticClass:"stories-slide",attrs:{"id":"stories-file"}},[_c('div',{staticClass:"stories-slide-overlay"},[_c('div',{staticClass:"overlay-text"},[_c('span',[_vm._v(_vm._s(_vm.eventName))]),_c('span',[_vm._v(" "+_vm._s(_vm.eventDate)+" ")])]),_c('div',{staticClass:"spartan-logo"},[_c('img',{attrs:{"src":require("@/assets/spartan_logo_with_title.svg"),"alt":"spartan"}})])]),_c('img',{staticClass:"slide-image",attrs:{"src":require("@/assets/event_share_stories.png"),"alt":""}})])])])])],1)]:_vm._e(),_c('div',{staticClass:"slider-pagination"},[_c('div',{staticClass:"pagination-item",class:{ 'is-active': _vm.selectedSlideIndex === 0 }}),_c('div',{staticClass:"pagination-item",class:{ 'is-active': _vm.selectedSlideIndex === 1 }})]),_c('div',{staticClass:"overlay-buttons"},[_c('div',{staticClass:"file-download",class:{
          'download-stories-file': _vm.selectedSlideIndex === 0,
          'download-feed-file': _vm.selectedSlideIndex === 1,
        },on:{"click":function($event){return _vm.shareFile('download')}}},[(!_vm.isLoading.download)?_c('i',{staticClass:"material-icons"},[_vm._v(" download ")]):_vm._e(),(_vm.isLoading.download)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#000000","size":"25","width":"2"}}):_vm._e()],1),_c('mem-button',{class:{
          'share-stories-btn': _vm.selectedSlideIndex === 0,
          'share-feed-btn': _vm.selectedSlideIndex === 1,
        },attrs:{"btn-type":'secondary-dark',"loading":_vm.isLoading.share},on:{"click":function($event){return _vm.shareFile('share')}}},[(_vm.selectedSlideIndex === 0)?_c('span',[_vm._v(_vm._s(_vm.$t("share_modal_btn_stories")))]):_vm._e(),(_vm.selectedSlideIndex === 1)?_c('span',[_vm._v(_vm._s(_vm.$t("share_modal_btn_feed")))]):_vm._e()])],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }